<template>
  <div>
    <div class="top">
      <img class="header-logo" :src="require(`@/assets/logo.png`)" />
      <el-tabs class="list" :stretch='true' v-model="sChange" @tab-click="handleClick">
        <el-tab-pane label="首页" name="home"></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="技术服务" name="technology"></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="产品介绍" name="introduce"></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="合作案例" name="example"></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="关于我们" name="contact"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="a1">
        <slot :name="sChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
      sChange: "home",
      display: 0,
    };
  },
  methods: {
    handleClick(tab) {
        this.sChange = tab.name
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-tabs__item { padding-right: 140px;}
.top {
  width: 100vw;
  position: fixed;
  top: 0px;
  height: 66px;
  // background: rgba($color: #ffffff, $alpha: 0.4); 
  background: #FFFFFF;
  box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
  display: flex;
  justify-content: center;
  z-index: 999;
  margin-right: 10px;
  .list {
    margin-top: 15px;
    margin-right: 200px;
    margin-left: 200px;
  }
  .header-logo {
    width: 117px;
    height: 37px;
    position: absolute;
    left: 10vw;
    top: 17px;
    z-index: 999;
  }
}
.a1 {
  // width: 100vw;
}
</style>