<template>
  <div style="width: 100vw;background: #F7FAFF;">
   <div style="display: flex;justify-content: center;">
      <img style="width: 1920px;height: auto;position: relative;" :src="require(`@/assets/image/banner5.png`)"/>
      <div class="pall">全行业全场景</div>
      <div class="psas"><span style="color:#006CFF;width:">解<span style="marginRight: 4px;"></span>决<span style="marginRight: 4px;"></span>方<span style="marginRight: 4px;"></span>案<span style="marginRight: 4px;"></span></span><span style="marginRight: 4px;"></span>服<span style="marginRight: 4px;"></span>务<span style="marginRight: 4px;"></span>商</div>
      <span class="pdtal">心怀匠人之心，依托信息时代背景，努力打造符合未来趋势的精英产品</span>
    </div>
    <div class="lxin">
      <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
      <span class="lx">公司简介</span>
      <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
    </div>
    <div style="marginTop: 19px;display: flex;justify-content: center;align-items: center;">
      <span style="width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;">
        成熟稳定的SaaS系统，可满足全场景和垂直行业的个性化需求
      </span>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="display: flex;align-items: center;marginTop:114px;width: 1200px;height: 550px;background: #FFFFFF;z-index: 99;">
        <img style="height:387px;width:730px;" src="../assets/image/cha.png" alt="" />
        <div class="bj">
          <span class="about">About  Us</span>
          <span class="detail">链行（上海）数字科技有限公司专注于为商家打造去中心化的数字化转型SaaS产品和技术服务，致力为客户提供先进的数字化平台、技术和解决方案，助力商家经营可持续增长。公司拥有经验丰富、技术成熟的研发团队，通过云计算架构、微服务架构、大数据共享云等技术助力各行各业商家数字化转型升级赋能。</span>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;position: relative;align-items: center;">
      <img style="width:100vw;marginTop:-45px;" src="../assets/image/blue.png" alt="" />
      <div style="display: flex;justify-content: center;position: absolute;align-items: center;">
        <div style="display: flex;flex-direction: column;transform: translateY(-50%);">
          <span style="marginBottom: 10px;width: 152px;height: 36px;font-size: 38px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;line-height: 82px;">联系我们</span>
          <span style="marginTop: 10px;width: 150px;height: 22px;font-size: 28px;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 82px;">Contact  Us</span>
        </div>
        <div style="display: flex;flex-direction: column;marginLeft:10vw;">
          <div style="position: relative;">
            <img style="width:24px;height:24px;position: absolute;top:10px" src="../assets/icon/wj.png" alt="" />
            <span style="marginLeft:40px;width: 278px;height: 20px;font-size: 20px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 44px;">链行（上海）数字科技有限公司</span>  
          </div>  
          <div style="position: relative;">
            <img style="width:24px;height:24px;position: absolute;top:10px" src="../assets/icon/email.png" alt="" />
            <span style="marginLeft:40px;width: 215px;height: 20px;font-size: 20px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 44px;">lianxing2023@163.com</span>  
          </div>  
          <div style="position: relative;">
            <img style="width:24px;height:24px;position: absolute;top:10px" src="../assets/icon/gd.png" alt="" />
            <span style="marginLeft:40px;width: 477px;height: 20px;font-size: 20px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 44px;">上海市闵行区虹桥进口商品展示交易中心B区8号楼2楼</span>  
          </div>  
        </div>  
      </div>
    </div>
    <div style="marginTop:1px">
      <img style="width: 100vw;height: 232px;background: #FFFFFF;" src="../assets/image/ditu.png" alt="">
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ContactView',
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {

    }
  }

}
</script>
  
<style lang="scss" scoped>
  .pall {
    position: absolute;
    top: 279px;
    left: 18.75%;
    right: 1343px;
    bottom: 506px;
    width: 217px;
    height: 35px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #252935;
    z-index: 99;
  }
  .psas {
    position: absolute;
    top: 342px;
    left: 18.7%;
    right: 1037px;
    width: 524px;
    height: 77px;
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 90px;
  }
  .pdtal {
    position: absolute;
    top: 478px;
    left: 18.75%;
    right: 1037px;
    width: 550px;
    height: 63px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #484B4D;
    line-height: 40px;
  }
  .lxin {
    margin-top: 114px;
    display: flex;
    justify-content: center;
    align-items: center;

    .lx {
      width: 188px;
      height: 46px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }
  .bj {
    display: flex;
    flex-direction: column;
    .about {
      width: 207px;
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #252A36;
      line-height: 90px;
      white-space: nowrap;
    }
    .detail {
      margin-left: -4px;
      width: 441px;
      height: 295px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #525355;
      line-height: 46px;
    }
  }
</style>
  