<template>
  <div style="width: 100vw;">
    <div style="display: flex;justify-content: center;">
      <img style="width: 1920px;height: auto;position: relative;" :src="require(`@/assets/image/banner1.png`)"/>
      <div class="pall">全行业全场景</div>
      <div class="psas"><span style="color:#006CFF;width:">S<span style="marginRight: 4px;"></span>a<span style="marginRight: 4px;"></span>a<span style="marginRight: 4px;"></span>S<span style="marginRight: 4px;"></span></span><span style="marginRight: 4px;"></span>解<span style="marginRight: 4px;"></span>决<span style="marginRight: 4px;"></span>方<span style="marginRight: 4px;"></span>案</div>
      <span class="pdtal">为客户提供稳定、可靠、安全的产品服务，助力企业实现数字化转型</span>
    </div>
    <div class="lxin">
      <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
      <span class="lx">链行云SaaS系统</span>
      <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
    </div>
    <div style="marginTop: 19px;display: flex;justify-content: center;align-items: center;">
      <span style="width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;">
        成熟稳定的SaaS系统，可满足全场景和垂直行业的个性化需求
      </span>
    </div>
    <div class="some">
      <img class="a4" src="../assets/image/yuan.png" alt="" />
      <div class="a1">
        <img class="b1" src="../assets/icon/shop.png" alt="" />
        <div class="c1">单商户</div>
        <span class="d1">成功稳定的单商户商城系统，自定义商城风格，上下架商品，让企业快速构建自己的品牌商城</span>
      </div>
      <div class="a2">
        <div class="c2">全终端</div>
        <img class="b2" src="../assets/icon/allcpt.png" alt="" />
        <span class="d2">PC＋H5＋APP＋小程序，多端数据打通，全渠道覆盖</span>
      </div>
      <div class="a3">
        <img class="b3" src="../assets/icon/sshop.png" alt="" />
        <div class="c3">多商户</div>
        <span class="d3">支持平台自营＋商家入驻的经营模式，支持多商圈线上线下联营模式</span>
      </div>
      <div class="a5">
        <div class="c5">全场景</div>
        <img class="b5" src="../assets/icon/mroe.png" alt="" />
        <span class="d5">支持各种业务场景实现，限时秒杀、专题活动、优惠券等</span>
      </div>
      <div class="a6">
        <img class="b6" src="../assets/icon/Stores.png" alt="" />
        <div class="c6">多门店</div>
        <span class="d6">支持门店配送与定位相结合的形式，线上线下数据打通，实现门店平台化管理的新零售模式</span>
      </div>
      <div class="a7">
        <img class="b7" src="../assets/icon/industry.png" alt="" />
        <div class="c7">全行业</div>
        <span class="d7">支持满足各垂直行业的数字化转型需求，打造具备行业特点的线上交易平台</span>
      </div>
    </div>
   <div style="display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 1128px;position: relative;" src="../assets/image/bbgd.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">SaaS商城系统核心功能</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>  
      <div style="position: absolute;marginTop: 19px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <span style="margin-top: 149px;width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;white-space: nowrap;">
          完善的服务能力，为商家提供全方位一站式服务，实现高效经营
        </span>
      </div>
      <div style="position: absolute;marginTop:296px;width:1520px;height:756px;backgourd:#ddd;" @mouseenter="isch" @mouseleave="disch">
        <div style="position: absolute;left:520px;">
          <img style="width:517px;height:696px;z-index:999;transition: 2s;" :src="swppic==0?pico:swppic==1?picw:swppic==2?pict:picf" alt="" />
        </div>
        <div style="position: absolute;left: 155px;top: 190px;"> 
          <img style="transition: 2s;" :src="swppic==0?dpicf:swppic==1?dpico:swppic==2?dpicw:dpict" alt="" />
        </div>
        <div style="position: absolute;top: 190px;left: 1025px;">
          <img style="transition: 2s;" :src="swppic==0?dpicw:swppic==1?dpict:swppic==2?dpicf:dpico" alt="" />
        </div>
        <div style="position: absolute;top: 355px;" @click="swpChange">
          <img src="../assets/images/curr_prev.png" alt="" />
        </div>
        <div style="position: absolute;right: 0;top: 355px;" @click="swpChanger1">
          <img src="../assets/images/curr_next.png" alt="" />
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 954px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 672px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">自定义组件，轻松搭建在线商城</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 580px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            多种装修组件，满足不同行业搭建需求，快速搭建个性化独立商城
          </div>
        </div>
        <div style="display: flex;justify-content: center;marginTop:120px;">
          <div style="width:1300px;height:589px;display: flex;justify-content: space-between;">
            <div style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;height:589px;">
              <div @mouseenter="chenge(0)" :style="nig==0?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;transition: all 0.4s;" :src="nig==0?url7:url1" alt="" />
                <span :style="nig==0?coloro:colort" class="udbx">家居类</span>
              </div>
              <div @mouseenter="chenge(1)" :style="nig==1?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;" :src="nig==1?url8:url2" alt="" />
                <span :style="nig==1?coloro:colort" class="udbx">食品类</span>
              </div>
              <div @mouseenter="chenge(2)" :style="nig==2?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;transition: all 0.4s;" :src="nig==2?url9:url3" alt="" />
                <span :style="nig==2?coloro:colort" class="udbx">生鲜类</span>
              </div>
              <div @mouseenter="chenge(3)" :style="nig==3?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;transition: all 0.4s;" :src="nig==3?url10:url4" alt="" />
                <span :style="nig==3?coloro:colort" class="udbx">母婴类</span>
              </div>
              <div @mouseenter="chenge(4)" :style="nig==4?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;transition: all 0.4s;" :src="nig==4?url11:url5" alt="" />
                <span :style="nig==4?coloro:colort" class="udbx">酒店类</span>
              </div>
              <div @mouseenter="chenge(5)" :style="nig==5?color:color1" class="udb">
                <img style="position: absolute;left:52px;top:22px;transition: all 0.4s;" :src="nig==5?url12:url6" alt="" />
                <span :style="nig==5?coloro:colort" class="udbx">医疗类</span>
              </div>
            </div>
            <div style="height:529px;">
              <img :src="niavt" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 1064px;background: linear-gradient(0deg, #EAF2FF, #FFFFFF);">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 690px;height: 46px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;white-space: nowrap;">SaaS商城系统整套技术架构交付</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:24px;">
          <div style="width: 580px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            多年来一直注重于用户体验，不断锤炼产品，只为更好的用户体验
          </div>
        </div>
        <div style="display: flex;justify-content: center;marginTop:120px;">
          <div style="width:1300px;height:589px;display: flex;justify-content: space-between;">
            <div style="height:529px;">
              <img :src="niavt1" alt="" />
            </div>
            <div style="display: flex;flex-flow: wrap;justify-content: space-around;height:599px;width:437px;">
              <div @mouseenter="chenger(0)" :class="nigx==0? 'udbta':'udbt'">
                <div :class="nigx==0? 'udbt1':'udbta1'">01</div>
                <span :class="nigx==0? 'udbxert':'udbxer'">线上商城，一键生成，均支持独立运营</span>
                <span v-show="nigx==0" style="transition: 3s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(1)" :class="nigx==1? 'udbta':'udbt'">
                <div :class="nigx==1? 'udbt1':'udbta1'">02</div>
                <span :class="nigx==1? 'udbxert':'udbxer'">提供丰富标准的营销组件库，即选即用</span>
                <span v-show="nigx==1" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(2)" :class="nigx==2? 'udbta':'udbt'">
                <div :class="nigx==2? 'udbt1':'udbta1'">03</div>
                <span :class="nigx==2? 'udbxert':'udbxer'">支持首页DIY，千店千面</span>
                <span v-show="nigx==2" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(3)" :class="nigx==3? 'udbta':'udbt'">
                <div :class="nigx==3? 'udbt1':'udbta1'">04</div>
                <span :class="nigx==3? 'udbxert':'udbxer'">支付多样化，独立支付通道</span>
                <span v-show="nigx==3" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(4)" :class="nigx==4? 'udbta':'udbt'">
                <div :class="nigx==4? 'udbt1':'udbta1'">05</div>
                <span :class="nigx==4? 'udbxert':'udbxer'">独立商家体系</span>
                <span v-show="nigx==4" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(5)" :class="nigx==5? 'udbta':'udbt'">
                <div :class="nigx==5? 'udbt1':'udbta1'">06</div>
                <span :class="nigx==5? 'udbxert':'udbxer'">独立商品交易平台</span>
                <span v-show="nigx==5" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(6)" :class="nigx==6? 'udbta':'udbt'">
                <div :class="nigx==6? 'udbt1':'udbta1'">07</div>
                <span :class="nigx==6? 'udbxert':'udbxer'">独立运营统计服务</span>
                <span v-show="nigx==6" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
              <div @mouseenter="chenger(7)" :class="nigx==7? 'udbta':'udbt'">
                <div :class="nigx==7? 'udbt1':'udbta1'">08</div>
                <span :class="nigx==7? 'udbxert':'udbxer'">完善的售后服务体系</span>
                <span v-show="nigx==7" style="transition: 1s;position: absolute;bottom:0;left:0;border-radius: 6px;width: 206px;height: 6px;background: #006CFF;"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 980px;position: relative;" src="../assets/image/b2bgd.png" alt="" />
      <div class="lxinll">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxll">服务优势</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>
      <div style="position: absolute;display: flex;flex-flow: wrap;justify-content: space-around;width:1200px;height:600px;marginTop:272px;">
        <div style="position: relative;">
          <img src="../assets/icon/bj1.png" alt="" />
          <img style="position: absolute;left:38px;top:58px;" src="../assets/icon/f1.png" alt=""/>
          <span style="position: absolute;left:38px;top:139px;width: 197px;height: 22px;font-size: 22px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;white-space: nowrap;">一站式数据集成</span>
          <span style="position: absolute;left:38px;top:176px;width: 223px;height: 42px;font-size: 16px;font-family: PingFang SC;font-weight: 500;color: #A6A9B2;line-height: 26px;">便捷接入，节能低耗，安全智能</span>
        </div>
        <img src="../assets/icon/bj2.png" alt="" />
        <div style="position: relative;">
          <img src="../assets/icon/bj1.png" alt="" />
          <img style="position: absolute;left:38px;top:58px;" src="../assets/icon/f2.png" alt=""/>
          <span style="position: absolute;left:38px;top:139px;width: 197px;height: 22px;font-size: 22px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;white-space: nowrap;">分布式云端服务架构</span>
          <span style="position: absolute;left:38px;top:176px;width: 223px;height: 42px;font-size: 16px;font-family: PingFang SC;font-weight: 500;color: #A6A9B2;line-height: 26px;">全案服务，管理有序</span>
        </div>
        <img src="../assets/icon/bj3.png" alt="" />
        <img src="../assets/icon/bj4.png" alt="" />
        <div style="position: relative;">
          <img src="../assets/icon/bj1.png" alt="" />
          <img style="position: absolute;left:38px;top:58px;" src="../assets/icon/f3.png" alt=""/>
          <span style="position: absolute;left:38px;top:139px;width: 197px;height: 22px;font-size: 22px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;white-space: nowrap;">实时监控与控制</span>
          <span style="position: absolute;left:38px;top:176px;width: 223px;height: 42px;font-size: 16px;font-family: PingFang SC;font-weight: 500;color: #A6A9B2;line-height: 26px;">全业务流程跟踪</span>
        </div>
        <img src="../assets/icon/bj5.png" alt="" />
        <div style="position: relative;">
          <img src="../assets/icon/bj1.png" alt="" />
          <img style="position: absolute;left:38px;top:58px;" src="../assets/icon/f4.png" alt=""/>
          <span style="position: absolute;left:38px;top:139px;width: 197px;height: 22px;font-size: 22px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;white-space: nowrap;">专业大数据分析</span>
          <span style="position: absolute;left:38px;top:176px;width: 223px;height: 42px;font-size: 16px;font-family: PingFang SC;font-weight: 500;color: #A6A9B2;line-height: 26px;">安全智能的全案服务，让管理有序化、集约化、高效化</span>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 562px;position: relative;" src="../assets/image/ibgd.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">专业技术服务团队，打造匠心产品</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>
      <div style="position: absolute;display: flex;flex-direction: row;flex-flow: wrap;justify-content: space-around;marginTop:284px;width:1300px;height:139px;backgourd:#ddd;">
        <dir class="display: flex;flex-direction: colum;">
          <img class="wh" src="../assets/icon/b1.png" alt="" />
          <div class="td">专业服务团队</div>
        </dir>
        <dir class="display: flex;flex-direction: colum;">
          <img class="wh" src="../assets/icon/b2.png" alt="" />
          <div class="td">企业需求诊断</div>
        </dir>
        <dir class="display: flex;flex-direction: colum;">
          <img class="wh" src="../assets/icon/b3.png" alt="" />
          <div class="td">解决方案输出</div>
        </dir>
        <dir class="display: flex;flex-direction: colum;">
          <img class="wh" src="../assets/icon/b4.png" alt="" />
          <div class="td">每月更新迭代</div>
        </dir>
        <dir class="display: flex;flex-direction: colum;">
          <img class="wh" src="../assets/icon/b5.png" alt="" />
          <div class="td">迅速响应服务</div>
        </dir>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.swpChanger()
  },
  data() {
    return {
      url1: require('../assets/icon/djia.png'),
      url2: require('../assets/icon/food.png'),
      url3: require('../assets/icon/ing.png'),
      url4: require('../assets/icon/baby.png'),
      url5: require('../assets/icon/hotel.png'),
      url6: require('../assets/icon/hospital.png'),
      url7: require('../assets/icon/jia.png'),
      url8: require('../assets/icon/dfood.png'),
      url9: require('../assets/icon/ding.png'),
      url10: require('../assets/icon/dbaby.png'),
      url11: require('../assets/icon/dhotel.png'),
      url12: require('../assets/icon/dhospital.png'),
      urlo: require('../assets/icon/house.png'),
      urlt: require('../assets/icon/fimg.png'),
      urlh: require('../assets/icon/simg.png'),
      urlf: require('../assets/icon/mimg.png'),
      urli: require('../assets/icon/himg.png'),
      urls: require('../assets/icon/yimg.png'),
      niavt: require('../assets/icon/house.png'),
      niavt1: require('../assets/image/g1.png'),
      img1: require('../assets/image/g1.png'),
      img2: require('../assets/image/g2.png'),
      img3: require('../assets/image/g3.png'),
      img4: require('../assets/image/g4.png'),
      img5: require('../assets/image/g5.png'),
      img6: require('../assets/image/g6.png'),
      img7: require('../assets/image/g7.png'),
      img8: require('../assets/image/g8.png'),
      pico: require('../assets/images/p1.png'),
      picw: require('../assets/images/p2.png'),
      pict: require('../assets/images/p3.png'),
      picf: require('../assets/images/p4.png'),
      dpico: require('../assets/images/tp1.png'),
      dpicw: require('../assets/images/tp2.png'),
      dpict: require('../assets/images/tp3.png'),
      dpicf: require('../assets/images/tp4.png'),
      color: 'background:#F7F7F7',
      color1: 'background:#ffffff',
      coloro: 'color:#252A36', 
      colort: 'color: #939599',
      nig: 0,
      nigx: 0,
      swppic: 0,
      swppict: 0,
      swich: true,
      ding: ''
    }
  },
  methods: {
    chenge(index) {
      this.nig = index;
      if(index == 0) {
        this.niavt = this.urlo;
      }else if(index == 1) {
        this.niavt = this.urlt;
      }else if(index == 2) {
        this.niavt = this.urlh;
      }else if(index == 3) {
        this.niavt = this.urlf;
      }else if(index == 4) {
        this.niavt = this.urli;  
      }else{
        this.niavt = this.urls;
      }
    },
    chenger(index) {
      this.nigx = index;
      if(index == 0) {
        this.niavt1 = this.img1;
      }else if(index == 1) {
        this.niavt1 = this.img2;
      }else if(index == 2) {
        this.niavt1 = this.img3;
      }else if(index == 3) {
        this.niavt1 = this.img4;
      }else if(index == 4) {
        this.niavt1 = this.img5;
      }else if(index == 5) {
        this.niavt1 = this.img6;
      }else if(index == 6) {
        this.niavt1 = this.img7;      
      }else{
        this.niavt1 = this.img8;
      }
    },
    swpChange() {
      if(this.swppic>0){
        this.swppic--;
      }else {
        this.swppic=4;
      }
    },
    isch() {
      this.swich=false;
      this.swpChanger();
      console.log('2');
    },
    disch() {
      this.swich=true;
      this.swpChanger()
      console.log('1');
    },
    swpChanger() {
      clearInterval(this.ding);
      if(this.swich!=true)return;
      this.ding = setInterval(() => {
        if(this.swppic<3){
          this.swppic++;
        }else {
          this.swppic=0;
        }
      },2500);
      this.ding;
    },
    swpChanger1() {
      if(this.swppic<3){
          this.swppic++;
        }else {
          this.swppic=0;
        }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../assets/css/style.css';
</style>
<style scoped>
  @import '../assets/css/swiper.min.css';
</style>
<style scoped lang="scss">
  .pall {
    position: absolute;
    top: 279px;
    left: 18.75%;
    right: 1343px;
    bottom: 506px;
    width: 217px;
    height: 35px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #252935;
    z-index: 99;
  }
  .psas {
    position: absolute;
    top: 342px;
    left: 18.7%;
    right: 1037px;
    width: 524px;
    height: 77px;
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 90px;
  }
  .pdtal {
    position: absolute;
    top: 478px;
    left: 18.75%;
    right: 1037px;
    width: 550px;
    height: 63px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #484B4D;
    line-height: 40px;
  }
  .lxin {
    margin-top: 114px;
    display: flex;
    justify-content: center;
    align-items: center;

    .lx {
      width: 355px;
      height: 46px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }
  .some {
    margin-top: 75px;
    width: 100%;
    height: 870px;
    background: #FFFFFF;
    user-select: none;
    display: flex;
    justify-content: center;
    position: relative;

    .a1 {
      position: absolute;
      top: 84px;
      left: 20.68%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 3.6s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b1 {
        position: absolute;
        width: 28px;
        height: 24px;
        left: 24px;
        top: 27px;
      }
      .c1 {
        position: absolute;
        left: 62px;
        top: 20px;
        width: 79px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        // line-height: 36px;
        white-space: nowrap;
      }
      .d1 {
        position: absolute;
        left: 62px;
        top: 65px;
        width: 252px;
        height: 74px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }
    .a2 {
      position: absolute;
      top: 84px;
      right: 20.58%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 3.6s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b2 {
        position: absolute;
        top: 26px;
        right: 24px;
        width: 27px;
        height: 24px;
      }
      .c2 {
        position: absolute;
        top: 20px;
        right: 64px;
        width: 82px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        line-height: 36px;
        white-space: nowrap;
      }
      .d2 {
        position: absolute;
        left: 24px;
        top: 65px;
        width: 252px;
        height: 46px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }
    .a3 {
      position: absolute;
      top: 338px;
      left: 18.75%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 2.7s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b3 {
        position: absolute;
        width: 28px;
        height: 24px;
        left: 24px;
        top: 27px;
      }
      .c3 {
        position: absolute;
        left: 62px;
        top: 20px;
        width: 79px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        // line-height: 36px;
        white-space: nowrap;
      }
      .d3 {
        position: absolute;
        left: 62px;
        top: 65px;
        width: 252px;
        height: 74px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }
    .a5 {
      position: absolute;
      top: 338px;
      right: 18.75%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 2.7s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b5 {
        position: absolute;
        top: 26px;
        right: 24px;
        width: 24px;
        height: 24px;
      }
      .c5 {
        position: absolute;
        top: 20px;
        right: 64px;
        width: 82px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        line-height: 36px;
        white-space: nowrap;
      }
      .d5 {
        position: absolute;
        top: 65px;
        right: 58px;
        width: 252px;
        height: 74px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }
    .a4 {
      width: 714px;
      height: 714px;
      animation: jumpBoxHandler 3.1s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
    }
    .a6 {
      position: absolute;
      top: 586px;
      left: 26.41%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 2.9s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b6 {
        position: absolute;
        width: 28px;
        height: 24px;
        left: 24px;
        top: 27px;
      }
      .c6 {
        position: absolute;
        left: 62px;
        top: 20px;
        width: 79px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        // line-height: 36px;
        white-space: nowrap;
      }
      .d6 {
        position: absolute;
        left: 62px;
        top: 65px;
        width: 252px;
        height: 74px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }
    .a7 {
      position: absolute;
      top: 586px;
      right: 26.41%;
      width: 340px;
      height: 170px;
      background: #FFFFFF;
      box-shadow: 0px 3px 24px 0px rgba(202,219,252,0.5);
      border-radius: 20px;
      animation: jumpBoxHandler 2.9s infinite;
      @keyframes jumpBoxHandler { /* css事件 */
        0% {
          transform: translate(0px, 0px);
        }
        50% {
          transform: translate(0px, 30px); /* 可配置跳动方向 */
        }
        100% {
          transform: translate(0px, 0px);
        }
      }
      .b7 {
        position: absolute;
        top: 23px;
        right: 24px;
        width: 24px;
        height: 27px;
      }
      .c7 {
        position: absolute;
        top: 20px;
        right: 64px;
        width: 82px;
        height: 27px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #252935;
        line-height: 36px;
        white-space: nowrap;
      }
      .d7 {
        position: absolute;
        top: 65px;
        right: 59px;
        width: 252px;
        height: 74px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #939599;
        line-height: 28px;
      }
    }  
  }
  .swip {
    position: relative;
    display: flex;
    justify-content: center;
    width:100%;
    .lxinl {
      position: absolute;
      margin-top: 114px;
      

      .lxl {
        width: 355px;
        height: 46px;
        font-size: 48px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #252A36;
      }
    }
  }
  .lxinl {
    position: absolute;
    margin-top: 114px;
    white-space: nowrap;
    
    .lxl {
      width: 355px;
      height: 46px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }
  .lxinll {
    position: absolute;
    margin-top: 114px;
    
    .lxll {
      width: 355px;
      height: 46px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #FFFFFF;
      white-space: nowrap;
    }
  }
  .udb {
    position: relative;width: 332px;height: 66px;background: #FFFFFF;border-radius: 10px;user-select: none;transition: 0.5s;
  }
  .udbx {
    position: absolute;left:88px;top:11px;width: 65px;height: 21px;font-size: 22px;font-family: PingFang SC;font-weight: bold;color: #939599;line-height: 44px;user-select: none;white-space: nowrap;
  }
  .udbt {
    position: relative;
    width: 204px;
    height: 136px;
    background: #FFFFFF;
    box-shadow: 0px 3px 18px 0px rgba(202,219,252,0.5);
    border-radius: 6px;
    user-select: none;
  }
  .udbta {
    position: relative;
    width: 204px;
    height: 136px;
    background: linear-gradient(180deg, #FFFFFF, #E0ECFF);
    box-shadow: 0px 3px 18px 0px rgba(202,219,252,0.5);
    border-radius: 6px;
    transition: 5s;
    user-select: none;
  }
  .udbt1 {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 29px;
    height: 20px;
    font-size: 27px;
    font-family: DINPro;
    font-weight: bold;
    color: #006CFF;
    line-height: 38px;
  }
  .udbta1 {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 26px;
    height: 20px;
    font-size: 27px;
    font-family: DINPro;
    font-weight: bold;
    color: #006CFF;
    line-height: 38px;
    transition: 1s;
  }
  .udbax {
    width: 30px;
    height: 20px;
    font-size: 28px;
    font-family: DINPro;
    font-weight: bold;
    color: #006CFF !important;
    line-height: 38px;
  }
  .udbxer {
    position: absolute;
    top: 62px;
    left: 24px;
    width: 163px;
    height: 43px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 550;
    color: #252936;
    line-height: 26px;
  }
  .udbxert {
    position: absolute;
    top: 62px;
    left: 24px;
    width: 163px;
    height: 45px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #252A36;
    line-height: 26px;
  }
  .wh {
    width: 116px;
    height: 94px;
  }
  .td {
    width: 107px;
    height: 17px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #252A36;
    line-height: 77px;
    margin-left: 10px;
    white-space: nowrap;
  }
</style>
  