<template>
  <div id="app" class="root">
    <div class="page">
      <SiteHeader>
        <HomeView slot="home" />
        <TechnologyView slot="technology" />
        <IntroduceView slot="introduce" />
        <ExampleView slot="example" />
        <ContactView slot="contact" />
      </SiteHeader>
      <SiteFooter />
    </div>
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import HomeView from './components/HomeView.vue'
import TechnologyView from './components/TechnologyView.vue'
import IntroduceView from './components/IntroduceView.vue'
import ExampleView from './components/ExampleView.vue'
import ContactView from './components/ContactView.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
    HomeView,
    TechnologyView,
    IntroduceView,
    ExampleView,
    ContactView
  }
}
</script>

<style lang="scss" scoped>
.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  // min-width: 1920px;
  // min-height: 1080px;
  // overflow: auto;

  .page {
    // width: 100vw;
  }
}
</style>
