import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import "@/scss/element.scss";
import element from './element/index'
Vue.use(element)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
