import {
    Dialog,
    Row,
    Col,
    Tabs,
    TabPane,
    Button
} from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(Dialog)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Button)
        Vue.use(Tabs);
        Vue.use(TabPane);

        // Vue.prototype.$message = Message;
        // Vue.prototype.$confirm = MessageBox.confirm;
        // Vue.prototype.$alert = MessageBox.alert;
        // Vue.prototype.$notify = Notification;

    }
}
export default element
