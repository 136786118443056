<template>
  <div style="width: 100vw;">
    <div style="display: flex;justify-content: center;">
      <img style="width: 1920px;height: auto;position: relative;" :src="require(`@/assets/image/banner4.png`)"/>
      <div class="psas"><span style="color:#006CFF;width:">新<span style="marginRight: 4px;"></span>商<span style="marginRight: 4px;"></span>业<span style="marginRight: 4px;"></span></span><span style="marginRight: 4px;"></span>操<span style="marginRight: 4px;"></span>作<span style="marginRight: 4px;"></span>系<span style="marginRight: 4px;"></span>统</div>
      <span class="pdtal">释放全新生产力，助力商家智慧经营提效</span>
    </div>
    <div style="position: relative;display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 2529px;position: relative;" src="../assets/image/hezuo.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">合作案例</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>  
      <div style="position: absolute;marginTop: 19px;marginLeft:99px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <span style="margin-top: 199px;width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;white-space: nowrap;">
          与全国各地上下游产业链伙伴共同打造协同经营新生态
        </span>
      </div>
      <div class="naive">
        <div class="nav" v-for="(item,index) in data" :key="index">
          <img class="im" :src="item.img" alt="">
          <div style="display: flex;flex-direction: row;justify-content: center;marginTop:269px;">
            <div style="height: 30px;font-size: 32px;font-family: Source Han Sans CN;font-weight: 500;color: #252A36;">{{item.name}}</div>
          </div>
          <div class="text">
            <div :style="item.color=='b'? 'background:#E5F0FF':item.color=='y'? 'background:#FFF5E5':item.color=='r'? 'background:#FFECEC':'background:#E7FBEA'" style="position: absolute;top:10px;display: flex;justify-content: center;width: 48px;height: 28px;border-radius: 5px;"><span  :style="item.color=='b'? 'color:#006CFF':item.color=='y'? 'color:#FF9900':item.color=='r'? 'color:#FF4040':'color:#15D62F'" style="width: 31px;height: 14px;font-size: 15px;margin-top: 3px;font-family: Source Han Sans CN;font-weight: 400;color: #FF4040;">{{item.type}}</span></div><div style="position: absolute;top:6px;width: 340px;height: 237px;font-size: 22px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 36px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ExampleView',
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      data: [
        {
          img: require('../assets/icon/moon.png'),
          name: '月牙云仓',
          type: '家居',
          text: '月牙云仓APP，为线下企业商户提供专属的线上分享及买卖平台，助力企业商户从地方到全国线上拓客、线上推广、招商销售，构建起属于新时代的新产业诚信生态，获得无边界的利润和数据流量价值。',
          color: 'b'
        },
        {
          img: require('../assets/icon/one.png'),
          name: '一上止正',
          type: '教育',
          text: '一上止正教育赋能中小微企业的同时打造会员企业商城，为消费者提供真产品、实价格的优质商品，更有多个产业空间的孵化，让中小微企业真正实现报团取暖、合作共赢。',
          color: 'y'
        },
        {
          img: require('../assets/icon/u.png'),
          name: 'U锂酒仓',
          type: '酒仓',
          text: 'U锂酒仓通过线上线下一体化的O2M及BLB的新商业模式，利用“互联网+数字技术”为酒水类市场赋能，打造酒水类线上+线下的交易网络生态，打造“消费3.0”无边界消费场景体验。',
          color: 'r'
        },
        {
          img: require('../assets/icon/max.png'),
          name: '极母数字空间站',
          type: '母婴',
          text: '极母时空数字空间站，依托SaaS云系统，实现用户数字化、服务数字化、产业数字化，线上线下营销一体化全渠道运营体系。九维空间赋能未来母婴产业创新升级，打造全渠道全场景女性综合调理智慧门店。',
          color: 'r'
        },
        {
          img: require('../assets/icon/czl.png'),
          name: '慈智力',
          type: '教育',
          text: '慈智力以教育为本，以赋能中小微企业为行，打造公平贸易，推动诚信生态，最终形成数据流、业务流、物流、价值流、现金流的商业生态环境。以诚信经营为基石，促使线上和线下的时间、空间、信息、场景的处处可得，时时可得的美好愿景。',
          color: 'y'
        },
        {
          img: require('../assets/icon/gray.png'),
          name: '安食惠',
          type: '食品',
          text: '安食惠致力于打造全品类安全食品供应链云平台，依托SaaS云系统，赋能加持全球食品企业在线融合，以用户价值为中心，设立社区服务网点，构建全球线上线下无边界通路，通过全新数字技术赋能安食惠全国社区运营。',
          color: 'y'
        },
        {
          img: require('../assets/icon/xiao.png'),
          name: '厂长老肖',
          type: '酒类',
          text: '以酒为媒，链接世界，莲塘高粱酒打响酒厂数字化转型升级第一枪。为各行各业赋能，打造出一个个高品质、高效率的数字经济企业。入驻酒仓赋能中小微企业商户、打造全方位展现自我的平台。',
          color: 'r'
        },
        {
          img: require('../assets/icon/zhuhai.png'),
          name: '珠海德翰酒店',
          type: '酒店',
          text: '珠海德翰酒店是一家标准五星级规模 ，入驻酒店云仓，其旗下的德翰酒店APP是酒店行业数字化转型的典型示范商户，现珠海德翰酒店加入云仓，再次升级数字化转型之路。',
          color: 'b'
        },
        {
          img: require('../assets/icon/sky.png'),
          name: '云中食鲜',
          type: '生鲜',
          text: '河南云中食鲜主营行业为生鲜行业，已入驻云仓商城，搭建生鲜行业APP服务大众面向全国，实现线上商城与线下实体经济共融共创的新商业模式。',
          color: 'g'
        },
      ]
    }
  }
}
</script>
  
<style lang="scss" scoped>
  .psas {
    position: absolute;
    top: 342px;
    left: 18.7%;
    right: 1037px;
    width: 524px;
    height: 77px;
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 90px;
  }
  .pdtal {
    position: absolute;
    top: 478px;
    left: 18.75%;
    right: 1037px;
    width: 550px;
    height: 63px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #484B4D;
    line-height: 40px;
  }
  .lxinl {
    position: absolute;
    margin-top: 114px;
    
    .lxl {
      width: 188px;
      height: 46px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }
  .naive {
    margin-top: 382px;
    margin-bottom: 114px;
    color:#555;
    position: absolute;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    width: 1200px;
    height: 2000px;
    .nav {
      width: 390px;
      height: 648px;
      background: #FFFFFF;
      box-shadow: 0px 2px 24px 0px rgba(231,239,252,0.5);
      border-radius: 8px;
      position: relative;
      .im {
        position: absolute;
        top: 70px;
        left: 116px;
        right: 116px;
        width: 158px;
        height: 158px;
      }
      .text {
        position: relative;
        left: 25px;
        width: 340px;
        height: 201px;
        margin-top: 52px;
        // font-size: 22px;
        // font-family: Source Han Sans CN;
        // font-weight: 400;
        // color: #525355;
        // line-height: 36px;
      }
    }
  }
</style>
  