<template>
    <div class="footer-box">
        <el-row :gutter="24" style="height: 168px;display: flex;align-items: center;">
            <el-col :span="6" :offset="3">
                <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                    <img style="width: 27px;height: 33px;" :src="require(`@/assets/mini-logo.png`)" />
                    <span style="font-size: 22px;color:  #252A36;margin-left: 15px;font-weight: bold;white-space: nowrap;   ">链行(上海)数字科技有限公司</span>
                </div>
            </el-col>
            <el-col :span="2" :offset="2">
                <div style="display: flex;flex-direction: column;align-items: center;">
                    <span style="font-size: 22px;font-weight: bold;color: #252A36;white-space: nowrap;">联系我们</span>
                    <span style="font-size: 18px;color: #525355;white-space: nowrap;">Contact Us</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div style="display: flex;flex-direction: column;">
                    <div style="display: flex;flex-direction: row;align-items: center;">
                        <img style="width: 24px;height: 24px;" :src="require(`@/assets/icon/icon-email.png`)" />&nbsp;&nbsp;&nbsp;
                        <span style="font-size: 20px;color: #252A36;white-space: nowrap;">lianxing2023@163.com</span>
                    </div>
                    <div style="display: flex;flex-direction: row;margin-top: 20px;">
                        <img style="width: 24px;height: 24px;" :src="require(`@/assets/icon/icon-address.png`)" />&nbsp;&nbsp;
                        <span style="font-size: 20px;color: #252A36;">上海市闵行区虹桥进口商品展示交易中心B区8号楼2楼</span>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div style="width: 100%;height: 1px;background: #EBEBEB ;" />
        <div
            style="height: 63px;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;">
            <img style="width: 14px;height: 14px;" :src="require(`@/assets/icon/icon-filings.png`)" />
            <span style="font-size: 12px;color: #525355;margin-left: 7px;">©Copyright 2023 链行（上海）数字科技有限公司 版权所有 |
                沪ICP备2022031056号-1</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SiteFooter',
}
</script>
<style lang="scss" scoped>
.footer-box {
    width: 100vw;
    height: 232px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
}
</style>