<template>
  <div style="width: 100vw;">
    <div style="display: flex;justify-content: center;">
      <img style="width: 1920px;height: auto;position: relative;" :src="require(`@/assets/image/banner2.png`)"/>
      <div class="pall">成熟稳定的</div>
      <div class="psas"><span style="color:#006CFF;width:">技<span style="marginRight: 4px;"></span>术<span style="marginRight: 4px;"></span></span><span style="marginRight: 4px;"></span>团<span style="marginRight: 4px;"></span>队<span style="marginRight: 4px;"></span>服<span style="marginRight: 4px;"></span>务</div>
      <span class="pdtal">多年技术沉淀，深入多个细分行业，为客户提供<br/>一站式开发服务</span>
    </div>
    <div style="marginTop:-86px;display: flex;justify-content: center;">
      <img style="z-index: 999;" src="../assets/image/1.png" alt="" />
      <img style="z-index: 999;" src="../assets/image/2.png" alt="" />
      <img style="z-index: 999;" src="../assets/image/3.png" alt="" />
    </div>
    <div style="position: relative;display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 1400px;position: relative;" src="../assets/image/bgd.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">SaaS云计算架构</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>  
      <div style="position: absolute;marginTop: 19px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <span style="margin-top: 199px;width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;white-space: nowrap;">
          完善的服务能力，为商家提供全方位一站式服务，实现高效经营
        </span>
      </div>
      <div style="position: absolute;bottom:114px;">
        <img src="../assets/image/spd.png" alt="" />
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 970px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 626px;height: 46px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;white-space: nowrap;">传统架构 vs 链行云SaaS架构</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
      </div>
      <div style="position: absolute;display: flex;justify-content: space-between;width:1222px;marginTop:293px;">
        <div style="position: relative;width:592px;height:544px;">
          <img style="position: absolute;" src="../assets/image/vs1.png" alt="">
          <div style="width:592px;height:94px;position: absolute;display: flex;justify-content: space-around;align-items: center;">
            <div style="width: 167px;height: 23px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #252A36;white-space: nowrap;">传统云计算架构</div>
            <div style="width: 176px;height: 23px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #252A36;white-space: nowrap;">SaaS云计算架构</div>
          </div>
          <div style="width:592px;height:450px;position: absolute;bottom:0;display: flex;justify-content: center;align-items: center;">
            <img src="../assets/image/vs.png" alt="">
          </div>
          <div style="height:450px;width:592px;position: absolute;bottom:0;display: flex;flex-direction: colum;justify-content: space-around;align-items: center;">
            <div style="display: flex;flex-direction: column;justify-content: center;height:360px;justify-content: space-around;width:141px;">
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">资源浪费</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">用不爽</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">撑不住</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">撑不住</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">不安全</div>
              </div>
            </div>
            
            <div style="display: flex;flex-direction: column;justify-content: center;height:360px;justify-content: space-around;width:141px;">
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">高效率/低成本</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">数据安全</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">可扩展性</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">可靠性</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">冗余性/高可用性</div>
              </div>
            </div>
          </div>
          <div style="width:592px;height:94px;position: absolute;display: flex;justify-content: center;align-items: center;"><span style="width: 1px;height: 39px;border: 1px solid #CBD2DB;"></span></div>
        </div>
        <div style="position: relative;width:592px;height:544px;">
          <img style="position: absolute;" src="../assets/image/vs1.png" alt="">
          <div style="width:592px;height:94px;position: absolute;display: flex;justify-content: space-around;align-items: center;">
            <div style="width: 143px;height: 23px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #252A36;white-space: nowrap;">传统单体架构</div>
            <div style="width: 176px;height: 23px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #252A36;white-space: nowrap;">SaaS微服务架构</div>
          </div>
          <div style="width:592px;height:450px;position: absolute;bottom:0;display: flex;justify-content: center;align-items: center;">
            <img src="../assets/image/vs.png" alt="">
          </div>
          <div style="height:450px;width:592px;position: absolute;bottom:0;display: flex;flex-direction: colum;justify-content: space-around;align-items: center;">
            <div style="display: flex;flex-direction: column;justify-content: center;height:360px;justify-content: space-around;width:141px;">
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">大泥球</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">扩展成本</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">强耦合</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/error.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">难迭代</div>
              </div>
            </div>
            
            <div style="display: flex;flex-direction: column;justify-content: center;height:360px;justify-content: space-around;width:141px;">
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">低耦合</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">复用</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">高效</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">异构</div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <img style="width:34px;height:34px;" src="../assets/icon/true.png" alt="" />
                <div style="height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;white-space: nowrap;">故障隔离</div>
              </div>
            </div>
          </div>
          <div style="width:592px;height:94px;position: absolute;display: flex;justify-content: center;align-items: center;"><span style="marginRight:16px;width: 1px;height: 39px;border: 1px solid #CBD2DB;"></span></div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 1026px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 199px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">简单易用</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 459px;height: 19px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            采用主流技术，专注电商领域，界面美观简单易用
          </div>
        </div>
        <div style="marginTop:117px;display: flex;justify-content: center;">
          <div style="height:631px;width:1200px;position: relative;">
            <img src="../assets/image/gxzw.png" alt="">
            <div style="position: absolute;top:233px;left:64px;white-space: nowrap;width: 141px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: bold;color: #252A36;">国内主流技术</div>
            <div style="position: absolute;top:296px;left:28px;width: 237px;height: 137px;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 30px;">基于Java17、Springboot3、 SpringCloud、 Uniapp、Vue3等主流技术研发，专注于电商软件研发及相关服务的提供</div>
            <div style="position: absolute;top:136px;left:378px;white-space: nowrap;width: 141px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: bold;color: #252A36;">系统开发升级</div>
            <div style="position: absolute;top:187px;left:346px;width: 212px;height: 137px;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 30px;">适应市场需要，帮助您的企业增强竞争力，在日新月异的电商行业中立于领先地位</div>
            <div style="position: absolute;top:233px;left:680px;white-space: nowrap;width: 141px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: bold;color: #252A36;">专注电商领域</div>
            <div style="position: absolute;top:296px;left:651px;;width: 212px;height: 137px;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 30px;">以先进的理念、优秀的产品和专业的服务，引领中国电子商务行业技术的发展方向</div>
            <div style="position: absolute;top:136px;left:1003px;white-space: nowrap;width: 141px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: bold;color: #252A36;">为开发而生</div>
            <div style="position: absolute;top:187px;left:958px;width: 212px;height: 137px;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 30px;">源码100%开源，独立部署开放式接口，工具随心扩展</div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 797px;position: relative;" src="../assets/image/bbgd.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">链行云服务</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>
      <div style="position: absolute;bottom:114px;width:1285px;height:483px;">
          <div style="position: relative;">
            <img style="position: absolute;top:15px;left:24px;" src="../assets/icon/dui.png" alt="" />
            <div style="position: absolute;width: 153px;white-space: nowrap;top:95px;left:155px;left:165px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: 800;color: #252A36;line-height: 32px;">专项服务团队</div>
          </div>
          <div style="position: relative;">
            <img style="position: absolute;top:15px;left:905px;" src="../assets/icon/die.png" alt="" />
            <div style="position: absolute;width: 153px;white-space: nowrap;top:385px;z-index:999;left:165px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: 800;color: #252A36;line-height: 32px;">迅速响应服务</div>
          </div>
          <div style="position: relative;">
            <img style="position: absolute;top:90px;left:360px;" src="../assets/image/bimg.png" alt="" />
          </div>
          <div style="position: relative;">
            <img style="position: absolute;top:308px;left:24px;" src="../assets/icon/su.png" alt="" />
            <div style="position: absolute;width: 153px;white-space: nowrap;top:95px;left:1035px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: 800;color: #252A36;line-height: 32px;">1-2次/月迭代优化</div>
          </div>
          <div style="position: relative;">
            <img style="position: absolute;top:305px;left:905px;" src="../assets/icon/cha.png" alt="" />
            <div style="position: absolute;width: 153px;white-space: nowrap;top:385px;left:1045px;height: 23px;font-size: 24px;font-family: PingFang SC;font-weight: 800;color: #252A36;line-height: 32px;">插件定制服务</div>
          </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'TechnologyView',
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  data() {
    return {

    }
  }

}
</script>
  
<style lang="scss" scoped>
  .pall {
    position: absolute;
    top: 279px;
    left: 18.75%;
    right: 1343px;
    bottom: 506px;
    width: 217px;
    height: 35px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #252935;
    z-index: 99;
  }
  .psas {
    position: absolute;
    top: 342px;
    left: 18.7%;
    right: 1037px;
    width: 524px;
    height: 77px;
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 90px;
  }
  .pdtal {
    position: absolute;
    top: 478px;
    left: 18.75%;
    right: 1037px;
    width: 550px;
    height: 63px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #484B4D;
    line-height: 40px;
  }
  .lxinl {
    position: absolute;
    margin-top: 114px;
    
    .lxl {
      width: 501px;
      height: 72px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }
</style>
  