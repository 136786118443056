<template>
  <div style="width: 100vw;">
    <div style="display: flex;justify-content: center;">
      <img style="width: 1920px;height: auto;position: relative;" :src="require(`@/assets/image/banner3.png`)"/>
      <div class="pall">全场景</div>
      <div class="psas"><span style="color:#006CFF;width:">解<span style="marginRight: 4px;"></span>决<span style="marginRight: 4px;"></span></span><span style="marginRight: 4px;"></span>方<span style="marginRight: 4px;"></span>案</div>
      <span class="pdtal">全场景全链路的SaaS产品，助力企业数字升级</span>
    </div>
    <div style="position: relative;display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 980px;position: relative;" src="../assets/image/cp1.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">SaaS商城系统核心功能</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>  
      <div style="position: absolute;marginTop: 19px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <span style="margin-top: 199px;width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;white-space: nowrap;">
          完善的服务能力，为商家提供全方位一站式服务，实现高效经营
        </span>
      </div>
      <div style="position: absolute;display: flex;flex-direction: row;flex-flow: wrap;marginTop:404px;width:1200px;height:396px;backgourd:#ddd;">
        <div style="position: relative;width: 300px;height: 164px;" v-for="(item,index) in data" :key="index">
          <img style="position: absolute;width: 70px;height: 70px;top:25px;left:115px;right:115px;" :src="item.img" alt="" />
          <span style="position: absolute;width: 88px;height: 32px;font-size: 22px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #252936;line-height: 32px;bottom:25px;left:106px;right:106px;">{{item.name}}</span>
          <span v-show="item.type==true" style="height:80px;width:0px;position: absolute;right:0;border: 1px solid #D2CFCF;top:42px;bottom:42px;"></span>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 970px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 816px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">多平台产品布局，数据打通，覆盖全网</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 649px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            搭建小程序+H5+APP+PC中台全渠道一体化销售渠道，全面覆盖销售群体
          </div>
        </div>
        <div style="display: flex;justify-content: center;marginTop:120px;">
          <div style="width:1200px;height:472px;display: flex;justify-content: space-around;">
            <div style="position: relative;width: 282px;height: 472px;background: linear-gradient(190deg, #EFF8FF 0%, #FBFDFF 43%, #F7FBFF 76%, #F1F8FE 100%);border-radius: 16px 16px 16px 16px;">
              <span style="position: absolute;top:48px;left:24px;width: 140px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">微信小程序</span>
              <span style="position: absolute;top:104px;left:24px;width: 234px;height: 54px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">流量大/易推广/体验好传播快，电商卖货优选</span>
              <img style="position: absolute;top:231px;left:25px;;" src="../assets/icon/mp.png" alt="" />
            </div>
            <div style="position: relative;width: 282px;height: 472px;background: linear-gradient(190deg, #EFF8FF 0%, #FBFDFF 43%, #F7FBFF 76%, #F1F8FE 100%);border-radius: 16px 16px 16px 16px;">
              <span style="position: absolute;top:48px;left:24px;width: 140px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">H5商城</span>
              <span style="position: absolute;top:104px;left:24px;width: 234px;height: 54px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">电脑端、手机端、任何浏览器都能访问，方便获客</span>
              <img style="position: absolute;top:231px;left:32px;;" src="../assets/icon/h5.png" alt="" />
            </div>
            <div style="position: relative;width: 282px;height: 472px;background: linear-gradient(190deg, #EFF8FF 0%, #FBFDFF 43%, #F7FBFF 76%, #F1F8FE 100%);border-radius: 16px 16px 16px 16px;">
              <span style="position: absolute;top:48px;left:24px;width: 140px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">APP商城</span>
              <span style="position: absolute;top:104px;left:24px;width: 234px;height: 54px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">IOS、Android app开发，客户粘性，自主性更高</span>
              <img style="position: absolute;top:231px;left:24px;;" src="../assets/icon/app.png" alt="" />
            </div>
            <div style="position: relative;width: 282px;height: 472px;background: linear-gradient(190deg, #EFF8FF 0%, #FBFDFF 43%, #F7FBFF 76%, #F1F8FE 100%);border-radius: 16px 16px 16px 16px;">
              <span style="position: absolute;top:48px;left:24px;width: 140px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">运营中台</span>
              <span style="position: absolute;top:104px;left:24px;width: 234px;height: 54px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">订单管理、商品发布、数据信息监控实时掌控</span>
              <img style="position: absolute;top:231px;left:15px;;" src="../assets/icon/mall.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative;display: flex;justify-content: center;height:100%">
      <img style="width: 1920px;height: 980px;position: relative;" src="../assets/image/cp2.png" alt="" />
      <div class="lxinl">
        <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
        <span class="lxl">多种业务模式系统</span>
        <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
      </div>  
      <div style="position: absolute;margin-right: -160px;marginTop: 19px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
        <span style="margin-top: 145px;width: 574px;height: 19px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #525355;line-height: 90px;white-space: nowrap;">
          全场景全链路的SaaS产品，助力企业数字升级
        </span>
      </div>
      <div style="position: absolute;display: flex;justify-content: center;marginTop:404px;">
        <div style="width: 1272px;height:320px;display: flex;justify-content: space-around;">
          <div style="position: relative;width: 282px;height: 320px;background: rgba(255,255,255,0.6);border-radius: 16px 16px 16px 16px;">
            <img style="position: absolute;top:40px;left:118px;" src="../assets/icon/uni02.png" alt="" />
            <span style="position: absolute;top:118px;left:85px;width: 112px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">线上商城</span>
            <span style="position: absolute;top:170px;left:30px;width: 234px;height: 81px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">线上购物商城系统，支持APP、H5和小程序，实现全渠道卖货+多场景营销</span>
          </div>
          <div style="position: relative;width: 282px;height: 320px;background: rgba(255,255,255,0.6);border-radius: 16px 16px 16px 16px;">
            <img style="position: absolute;top:40px;left:118px;" src="../assets/icon/uni07.png" alt="" />
            <span style="position: absolute;top:118px;left:85px;width: 112px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">本地生活</span>
            <span style="position: absolute;top:170px;left:24px;width: 234px;height: 81px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">为用户提供精准的本地生活资讯和服务，用户可在该商城
查询当地生活资讯</span>
          </div>
          <div style="position: relative;width: 282px;height: 320px;background: rgba(255,255,255,0.6);border-radius: 16px 16px 16px 16px;">
            <img style="position: absolute;top:40px;left:118px;" src="../assets/icon/uni04.png" alt="" />
            <span style="position: absolute;top:118px;left:85px;width: 112px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">二手交易</span>
            <span style="position: absolute;top:170px;left:34px;width: 234px;height: 81px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">二手交易服务平台，轻松出
闲置，交易有保障，自由市场，自由交易</span>
          </div>
          <div style="position: relative;width: 282px;height: 320px;background: rgba(255,255,255,0.6);border-radius: 16px 16px 16px 16px;">
            <img style="position: absolute;top:40px;left:118px;" src="../assets/icon/hu.png" alt="" />
            <span style="position: absolute;top:118px;left:85px;width: 112px;height: 36px;font-size: 28px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 36px;">三方集成</span>
            <span style="position: absolute;top:170px;left:24px;width: 234px;height: 81px;font-size: 18px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #3C3D3E;line-height: 27px;">集成第三方平台，实现不同应用系统的跨平台交互，数据互通，满足不同用户需求</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 970px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 384px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">插件玩法自由组合</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 540px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            用户可根据需求，配备不同玩法的商城系统，打造个性化系统
          </div>
        </div>
        <div style="display: flex;justify-content: center;marginTop:120px;">
          <div style="width:1300px;height:616px;display: flex;justify-content: space-between;">
            <div style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;height:616px;">
              <div @mouseenter="chenge(0)" :style="nig==0?color:color1" class="udb">
                <img style="position: absolute;left:25px;top:33px;" :src="nig==0?url1:url2" alt="" />
                <span :style="nig==0?colort:coloro" class="udbx">秒杀</span>
              </div>
              <div @mouseenter="chenge(1)" :style="nig==1?color:color1" class="udb">
                <img style="position: absolute;left:25px;top:33px;" :src="nig==1?url3:url4" alt="" />
                <span :style="nig==1?colort:coloro" class="udbx">拼团</span>
              </div>
              <div @mouseenter="chenge(2)" :style="nig==2?color:color1" class="udb">
                <img style="position: absolute;left:25px;top:33px;" :src="nig==2?url5:url6" alt="" />
                <span :style="nig==2?colort:coloro" class="udbx">专题</span>
              </div>
              <div @mouseenter="chenge(3)" :style="nig==3?color:color1" class="udb">
                <img style="position: absolute;left:25px;top:33px;" :src="nig==3?url7:url8" alt="" />
                <span :style="nig==3?colort:coloro" class="udbx">满减</span>
              </div>
              <div @mouseenter="chenge(4)" :style="nig==4?color:color1" class="udb">
                <img style="position: absolute;left:25px;top:33px;" :src="nig==4?url9:url10" alt="" />
                <span :style="nig==4?colort:coloro" class="udbx">优惠券</span>
              </div>
            </div>
            <div style="height:596px;">
              <img :src="niavt" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 970px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 672px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">独特的设计风格，拒绝千篇一律</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 580px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            多年来一直注重于用户体验，不断锤炼产品，只为更好的用户体验
          </div>
        </div>
        <div style="marginTop:135px;display: flex;justify-content: center;">
          <div style="width:1200px;height:506px;position: relative;" @mouseenter="isch" @mouseleave="disch">
            <div @click="swpChange" style="position: absolute;top:229px;">
              <img style="width:48px;height:48px;" src="../assets/images/left.png" alt="" />
            </div>
            <img style="position: absolute;left:160px;top:80px;opacity: 0.4;width: 204px;height: 380px;border-radius: 24px 24px 24px 24px;" :src="swppic==0?pics:swppic==1?pico:swppic==2?picw:swppic==3?pict:swppic==4?picf:pici" alt="" />
            <img style="position: absolute;left:464px;border-radius: 24px 24px 24px 24px;" :src="swppic==0?pico:swppic==1?picw:swppic==2?pict:swppic==3?picf:swppic==4?pici:pics" alt="" />
            <img style="position: absolute;right:160px;top:80px;opacity: 0.4;width: 204px;height: 380px;border-radius: 24px 24px 24px 24px;" :src="swppic==0?picw:swppic==1?pict:swppic==2?picf:swppic==3?pici:swppic==4?pics:pico" alt="" />
            <div @click="swpChanger" style="position: absolute;top:229px;right:0;">
              <img style="width:48px;height:48px;" src="../assets/images/right.png" alt="" />
            </div>
          </div>
        </div>
        <div style="marginTop:50px;display: flex;justify-content: center;">
          <div style="width: 138px;height: 8px;display: flex;justify-content: space-evenly;;">
            <span @mouseenter="swppic=0" :style="swppic==0?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="swppic=1" :style="swppic==1?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="swppic=2" :style="swppic==2?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="swppic=3" :style="swppic==3?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="swppic=4" :style="swppic==4?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="swppic=5" :style="swppic==5?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
          </div>
        </div>
        <div style="marginTop:40px;display: flex;justify-content: center;">
          <div style="width: 150px;height: 41px;display: flex;justify-content: center;align-items: center;border-radius: 8px 8px 8px 8px;opacity: 1;border: 1px solid #C7C7C7;">
            <div style="width: 48px;height: 30px;font-size: 18px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 300;color: #666666;white-space: nowrap;">
              {{swppic==0? '首页':swppic==1? '秒杀':swppic==2? '拼团':swppic==3? '专题':swppic==4? '优惠券 ':'满减'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 1162px;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 720px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">强大的运营中台，让经营实时掌控</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:12px;">
          <div style="width: 580px;height: 30px;font-size: 20px;font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #525355;line-height: 30px;white-space: nowrap;">
            管理功能全面，数据信息实时监控，统一后台管理，让管理更高效
          </div>
        </div>
        <div style="display: flex;justify-content: center;">
          <div style="width: 656px;height: 54px;marginTop:88px;display: flex;justify-content: space-evenly;">
            <div @mouseenter="num=0" style="userSelect:none;height:4px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
              <div style="width: 80px;height: 30px;font-size: 20px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #006CFF;">系统模板</div>
              <img :style="num==0?'opacity: 1;':'opacity: 0;'" style="width:16px;height:10px;marginTop:20px;" src="../assets/icon/icon.png" alt="">
            </div>
            <div @mouseenter="num=1" style="userSelect:none;height:4px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
              <div style="width: 80px;height: 30px;font-size: 20px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #006CFF;">收支明细</div>
              <img :style="num==1?'opacity: 1;':'opacity: 0;'" style="width:16px;height:10px;marginTop:20px;" src="../assets/icon/icon.png" alt="">
            </div>
            <div @mouseenter="num=2" style="userSelect:none;height:4px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
              <div style="width: 80px;height: 30px;font-size: 20px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #006CFF;">运营统计</div>
              <img :style="num==2?'opacity: 1;':'opacity: 0;'" style="width:16px;height:10px;marginTop:20px;" src="../assets/icon/icon.png" alt="">
            </div>
            <div @mouseenter="num=3" style="userSelect:none;height:4px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
              <div style="width: 80px;height: 30px;font-size: 20px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #006CFF;">订单管理</div>
              <img :style="num==3?'opacity: 1;':'opacity: 0;'" style="width:16px;height:10px;marginTop:20px;" src="../assets/icon/icon.png" alt="">
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: center;">
          <div style="height:424px;width:1189px;marginTop:50px;position: relative;" @mouseenter="ischo" @mouseleave="discho">
            <img @click="swpChange1" style="width: 506px;height: 284px;border-radius: 16px 16px 16px 16px;opacity: 0.4;border: 4px solid #393939;" :src="num==0? wpic3:num==1? wpic1:num==2?wpic2:wpic4" alt="" />
            <img style="position: absolute;left:211px;;width: 754px;height: 424px;border-radius: 16px 16px 16px 16px;z-index:999;border: 4px solid #393939;" :src="num==0? wpic1:num==1? wpic2:num==2?wpic4:wpic3" alt="" />
            <img @click="swpChanger1" style="margin-left: 160px;margin-top: 65px;width: 506px;height: 284px;border-radius: 16px 16px 16px 16px;opacity: 0.4;border: 4px solid #393939;" :src="num==0? wpic2:num==1? wpic4:num==2?wpic3:wpic1" alt="" />
          </div>
        </div>
        <div style="marginTop:50px;display: flex;justify-content: center;">
          <div style="width: 108px;height: 8px;display: flex;justify-content: space-evenly;;">
            <span @mouseenter="num=0" :style="num==0?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="num=1" :style="num==1?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="num=2" :style="num==2?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
            <span @mouseenter="num=3" :style="num==3?bgc:dbgc" style="width: 9px;height: 9px;border-radius: 50%;opacity: 1;"></span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <div style="width: 1920px;height: 970px;background: #F2F7FC;">
        <div style="display: flex;flex-direction: row;justify-content: center;marginTop:120px;">
          <img style="width: 31px;height:31px;marginRight:29px;marginTop:20px;" src="../assets/image/Lic.png" alt="" />
          <span style="width: 453px;height: 72px;font-size: 48px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #252936;line-height: 72px;">SaaS拥有巨大的潜力</span>
          <img style="width: 31px;height:31px;marginLeft:29px;marginTop:20px;" src="../assets/image/Ric.png" alt="" />
        </div>
        <div style="display: flex;justify-content: center;marginTop:120px;">
          <img src="../assets/image/Frame.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'IntroduceView',
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.swpChangero();
    this.swpChanger1t();
  },
  data() {
    return {
      url1: require('../assets/icon/nao.png'),
      url2: require('../assets/icon/dnao.png'),
      url3: require('../assets/icon/tuan.png'),
      url4: require('../assets/icon/dtuan.png'),
      url5: require('../assets/icon/ti.png'),
      url6: require('../assets/icon/dti.png'),
      url7: require('../assets/icon/man.png'),
      url8: require('../assets/icon/dman.png'),
      url9: require('../assets/icon/you.png'),
      url10: require('../assets/icon/dyou.png'),
      urlo: require('../assets/icon/miao.png'),
      urlt: require('../assets/icon/pin.png'),
      urlh: require('../assets/icon/zhuan.png'),
      urlf: require('../assets/icon/jian.png'),
      urli: require('../assets/icon/hui.png'),
      niavt: require('../assets/icon/miao.png'),
      pico: require('../assets/images/i1.png'),
      picw: require('../assets/images/i2.png'),
      pict: require('../assets/images/i3.png'),
      picf: require('../assets/images/i4.png'),
      pici: require('../assets/images/i5.png'),
      pics: require('../assets/images/i6.png'),
      wpic1: require('../assets/images/w1.png'),
      wpic2: require('../assets/images/w2.png'),
      wpic3: require('../assets/images/w3.png'),
      wpic4: require('../assets/images/w4.png'),
      data: [
        {
          img: require('../assets/icon/icon1.png'),
          name: '用户系统',
          type: true
        },
        {
          img: require('../assets/icon/icon2.png'),
          name: '商户系统',
          type: true
        },
        {
          img: require('../assets/icon/icon3.png'),
          name: '商品系统',
          type: true
        },
        {
          img: require('../assets/icon/icon4.png'),
          name: '订单系统',
          type: false
        },
        {
          img: require('../assets/icon/icon5.png'),
          name: '营销系统',
          type: true
        },
        {
          img: require('../assets/icon/icon6.png'),
          name: '组件系统',
          type: true
        },
        {
          img: require('../assets/icon/icon7.png'),
          name: '店铺管理',
          type: true
        },
        {
          img: require('../assets/icon/icon8.png'),
          name: '支付分账',
          type: false
        },
      ],
      color: 'background:#EEF5FF',
      color1: 'background:#ffffff',
      coloro: 'color:#252936', 
      colort: 'color: #006CFF',
      bgc: 'background: #006CFF',
      dbgc: 'background: #C7C7C7',
      nig: 0,
      swppic: 0,
      swppict: 0,
      swppic1: 0,
      swppict1: 0,
      num: 0,
      swich: true,
      swicho: true,
      ding: '',
      ding1: ''
    }
  },
  methods: {
    chenge(index) {
      this.nig = index;
      if(index == 0) {
        this.niavt = this.urlo;
      }else if(index == 1) {
        this.niavt = this.urlt;
      }else if(index == 2) {
        this.niavt = this.urlh;
      }else if(index == 3) {
        this.niavt = this.urlf;
      }else{
        this.niavt = this.urli;
      }
    },
    swpChange() {
      if(this.swppic>0){
        this.swppic--;
      }else {
        this.swppic=5;
      }
    },
    swpChanger() {
      if(this.swppic<5){
        this.swppic++;
      }else {
        this.swppic=0;
      }
    },
    swpChangero() {
      clearInterval(this.ding);
      if(this.swich!=true)return;
      this.ding = setInterval(() => {
        if(this.swppic<5){
          this.swppic++;
        }else {
          this.swppic=0;
        }
      },2500)
      this.ding;
    },
    isch() {
      this.swich=false;
      this.swpChangero();
      console.log('2');
    },
    disch() {
      this.swich=true;
      this.swpChangero()
      console.log('1');
    },
    ischo() {
      this.swicho=false;
      this.swpChanger1t();
      console.log('22');
    },
    discho() {
      this.swicho=true;
      this.swpChanger1t()
      console.log('11');
    },
    swpChange1() {
      if(this.num>0){
        this.num--;
      }else {
        this.num=4;
      }
    },
    swpChanger1() {
      if(this.num<3){
        this.num++;
      }else {
        this.num=0;
      }
    },
    swpChanger1t() {
      clearInterval(this.ding1);
      if(this.swicho!=true)return;
      this.ding1 = setInterval(() => {
        if(this.num<3){
          this.num++;
        }else {
          this.num=0;
        }
      },2500);
      this.ding1;
    },
  },
}
</script>
  
<style lang="scss" scoped>
.pall {
    position: absolute;
    top: 279px;
    left: 18.75%;
    right: 1343px;
    bottom: 506px;
    width: 217px;
    height: 35px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #252935;
    z-index: 99;
  }
  .psas {
    position: absolute;
    top: 342px;
    left: 18.7%;
    right: 1037px;
    width: 524px;
    height: 77px;
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 90px;
  }
  .pdtal {
    position: absolute;
    top: 478px;
    left: 18.75%;
    right: 1037px;
    width: 550px;
    height: 63px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #484B4D;
    line-height: 40px;
  }
  .lxinl {
    position: absolute;
    margin-top: 114px;
    white-space: nowrap;
    
    .lxl {
      width: 501px;
      height: 72px;
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #252A36;
      white-space: nowrap;
    }
  }

  .udb {
    position: relative;width: 282px;height: 104px;border-radius: 16px 16px 16px 16px;border: 1px solid #D7D7D7;user-select: none;transition: 1s;
  }
  .udbx {
    position: absolute;left:78px;top:34px;width: 72px;height: 36px;font-size: 24px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;line-height: 36px;user-select: none;transition: 1s;
  }
</style>
  